import { all, takeEvery, put, call } from 'redux-saga/effects'

import { history } from '../../index'
import useAxiosRequest from '../../hooks/useAxiosRequest'

import actions from './actions'
import { notification } from 'antd'
import { rollbackLogin } from '../../util/functions/rollbackLogin'
import { ErrorMessage } from '../../models'
import { WarningMessage } from '../../models/Messages/WarningMessage'
import { CustomerAPI } from '../../models/Domains/API/CustomerAPI.class'
import { ServiceProviderAPI } from '../../models/Domains/API/ServiceProviderAPI.class'

import axios from 'axios'

export function* LOGIN({ payload }) {
  const { email, password } = payload
  try {
    // Set loading
    yield put({ type: 'user/SET_STATE', payload: { loading: true } })


    // New api request
    try {
      const newLogin = yield call(
        axios.post,
        process.env.REACT_APP_AUTH_API_URL + '/login',
        {
          email,
          password,
        },
      )
    } catch (error) {
      console.error(error)
    }

    // API request
    const login = yield call(
      useAxiosRequest,
      '/api/login',
      {
        email,
        password,
      },
      'post',
    )

    // Check if success
    if (login.data && login.data.token) {
      const loginResponse = login.data

      if (!loginResponse.user.email_verified_at) {
        window.localStorage.setItem('apiToken', loginResponse.token)

        yield rollbackLogin()

        yield put({ type: 'user/SET_STATE', payload: { loading: false } })
        return notification.error({
          message: 'Error',
          description: ErrorMessage.login.emailUnverified,
        })
      }

      localStorage.setItem('apiToken', loginResponse.token)

      // Set active user type based on priority
      let activeUserType = 'buyer'
      if (loginResponse.user.is_partner) {
        activeUserType = 'service-provider'
      } else if (loginResponse.user.is_seller) {
        activeUserType = 'seller'
      }
      yield put({ type: actions.SET_ACTIVE_USER_TYPE, payload: activeUserType })

      // Set available user types based on user roles
      let availableUserTypes = []
      if (loginResponse.user.is_buyer) {
        availableUserTypes.push('buyer')
      }
      if (loginResponse.user.is_partner) {
        availableUserTypes.push('service-provider')
      }
      if (loginResponse.user.is_seller) {
        availableUserTypes.push('seller')
      }
      yield put({ type: actions.SET_AVAILABLE_USER_TYPES, payload: availableUserTypes })

      // TODO: Implement Subscription API

      let subscription
      try {
        const customerAPI = new CustomerAPI()
        subscription = yield customerAPI.getSubscription(loginResponse.user.id)
      } catch (error) {
        console.error(error)
      }

      if (loginResponse.user.is_partner) {
        const data = yield ServiceProviderAPI.getByUser(loginResponse.user.id)
        loginResponse.user.serviceProvider = data.serviceProvider || null
      }

      switch (loginResponse.user.status) {
        case 0:
          notification.warning({
            message: 'Warning',
            description: WarningMessage.login.suspended,
          })
          break
        case 2:
          window.localStorage.setItem('apiToken', loginResponse.token)

          yield rollbackLogin()

          yield put({ type: 'user/SET_STATE', payload: { loading: false } })
          return notification.error({
            message: 'Error',
            description: ErrorMessage.login.blocked,
          })
        case 3:
          notification.warning({
            message: 'Warning',
            description: WarningMessage.login.pendingApproval,
          })
          break
        default:
          break
      }

      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          authorized: true,
          user: loginResponse.user,
          subscription,
        },
      })
      yield history.push('/')
    } else {
      // Return error
      yield put({ type: 'user/SET_STATE', payload: { loading: false } })
      notification.error({
        message: 'Error',
        description: 'Your credentials did not match our records',
      })
    }
  } catch (e) {
    console.error(e)
    // Return error
    yield put({ type: 'user/SET_STATE', payload: { loading: false } })
    notification.error({
      message: 'Error',
      description: 'Your credentials did not match our records',
    })
  }
}

/**
 * Check if user logged in or redirect to login page
 */
export function* GET_CURRENT_ACCOUNT_OR_FAIL() {
  try {
    yield put({ type: 'user/SET_STATE', payload: { loading: true } })
    const authCheck = yield call(
      useAxiosRequest,
      '/api/v1/user-details',
      null,
      'get',
      true,
    )
    if (authCheck.data) {
      let subscription
      try {
        const customerAPI = new CustomerAPI()
        subscription = yield customerAPI.getSubscription(authCheck.data.user.id)
      } catch (error) {
        console.error(error)
      }

      if (authCheck.data.user.is_partner) {
        const data = yield ServiceProviderAPI.getByUser(authCheck.data.user.id)
        authCheck.data.user.serviceProvider = data.serviceProvider || null
      }

      // Set active user type based on priority
      let activeUserType = 'buyer'
      if (authCheck.data.user.is_partner) {
        activeUserType = 'service-provider'
      }
      if (authCheck.data.user.is_seller) {
        activeUserType = 'seller'
      }
      if (authCheck.data.user.sales_partner) {
        activeUserType = 'sales-partner'
      }

      yield put({ type: actions.SET_ACTIVE_USER_TYPE, payload: activeUserType })

      // Set available user types based on user roles
      let availableUserTypes = []
      if (authCheck.data.user.is_buyer) {
        availableUserTypes.push('buyer')
      }
      if (authCheck.data.user.is_partner) {
        availableUserTypes.push('service-provider')
      }
      if (authCheck.data.user.is_seller) {
        availableUserTypes.push('seller')
      }
      if (authCheck.data.user.sales_partner) {
        availableUserTypes.push('sales-partner')
      }
      yield put({ type: actions.SET_AVAILABLE_USER_TYPES, payload: availableUserTypes })

      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          authorized: true,
          user: authCheck.data.user,
          subscription,
        },
      })
    }
  } catch (e) {
    console.error(e)
    yield put({
      type: 'user/SET_STATE',
      payload: { loading: false, authorized: false, user: {} },
    })
    if (
      !/^\/marketplace(?=\/|$)/i.test(history.location.pathname) &&
      !/\/email/i.test(history.location.pathname) &&
      !/\/forgot-password/gi.test(history.location.pathname) &&
      !/\/restore-password/gi.test(history.location.pathname)
    ) {
      yield history.push('/secured/login')
    }
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  try {
    yield put({ type: 'user/SET_STATE', payload: { loading: true } })
    const authCheck = yield call(
      useAxiosRequest,
      '/api/v1/user-details',
      null,
      'get',
      true,
    )
    if (authCheck.data) {
      let subscription
      try {
        const customerAPI = new CustomerAPI()
        subscription = yield customerAPI.getSubscription(authCheck.data.user.id)
      } catch (error) {
        console.error(error)
      }

      if (authCheck.data.user.is_partner) {
        const data = yield ServiceProviderAPI.getByUser(authCheck.data.user.id)
        authCheck.data.user.serviceProvider = data.serviceProvider || null
      }

      // Set active user type based on priority
      let activeUserType = 'buyer'
      if (authCheck.data.user.is_partner) {
        activeUserType = 'service-provider'
      }
      if (authCheck.data.user.is_seller) {
        activeUserType = 'seller'
      }
      console.log(authCheck.data.user)
      if (authCheck.data.user.sales_partner) {
        activeUserType = 'sales-partner'
      }
      yield put({ type: actions.SET_ACTIVE_USER_TYPE, payload: activeUserType })

      // Set available user types based on user roles
      let availableUserTypes = []
      if (authCheck.data.user.is_buyer) {
        availableUserTypes.push('buyer')
      }
      if (authCheck.data.user.is_partner) {
        availableUserTypes.push('service-provider')
      }
      if (authCheck.data.user.is_seller) {
        availableUserTypes.push('seller')
      }
      if (authCheck.data.user.sales_partner) {
        availableUserTypes.push('sales-partner')
      }
      yield put({ type: actions.SET_AVAILABLE_USER_TYPES, payload: availableUserTypes })

      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          authorized: true,
          user: authCheck.data.user,
          subscription,
        },
      })
    }
  } catch (e) {
    console.error(e)
    yield put({
      type: 'user/SET_STATE',
      payload: { loading: false, authorized: false, user: {} },
    })
  }
}

export function* setActiveUserType({ payload }) {
  yield put({ type: 'user/SET_STATE', payload: { activeUserType: payload } })
}

export function* setAvailableUserTypes({ payload }) {
  yield put({ type: 'user/SET_STATE', payload: { availableUserTypes: payload } })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    GET_CURRENT_ACCOUNT_OR_FAIL(),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.SET_ACTIVE_USER_TYPE, setActiveUserType),
    takeEvery(actions.SET_AVAILABLE_USER_TYPES, setAvailableUserTypes),
  ])
}
