const logger = {
  error: (message, error) => {
    console.error(message, error)
    // You can add more sophisticated logging here
    // Like sending to a logging service
  },
  info: (message, data) => {
    console.info(message, data)
  },
  warn: (message, data) => {
    console.warn(message, data)
  }
}

export default logger
