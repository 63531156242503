import React from 'react'
import _ from 'lodash'
import { message } from 'antd'
import axios from 'axios'
import logger from '../../../utils/logger' // You may need to create this utility

// import previewImage from '../../../assets/products-upload/upload-image.png'

import axiosRequest from '../../../hooks/useAxiosRequest'

const ProductImages = ({ product, updateProduct }) => {
  const uploadImage = async (e, isMain = false) => {
    try {
      if (!e.target.files?.[0]) {
        message.error('Please select an image file')
        return
      }

      const file = e.target.files[0]
      message.loading({ content: 'Uploading image...', key: 'imageUpload' })

      const fileBuffer = Buffer.from(await file.arrayBuffer()).toJSON().data

      const { data } = await axios.post('https://editorial.tradeto.world/api/image-upload', {
        file: fileBuffer,
      })

      if (!data?.locations?.[0]) {
        throw new Error('Invalid response from image upload service')
      }

      const imageUrl = data.locations[0]

      // Structure the image object to maintain compatibility with existing code
      const imageObject = {
        original: imageUrl,
        medium: imageUrl,
        preview: imageUrl,
        extra_small: imageUrl
      }

      if (isMain) {
        updateProduct('mainImage', imageUrl)
      } else {
        const imagesArr = [...product.images, imageObject]
        updateProduct('images', imagesArr)
      }

      message.success({ content: 'Image uploaded successfully', key: 'imageUpload' })
      e.target.value = ''

    } catch (error) {
      logger.error('Error uploading image:', error)
      message.error({
        content: 'Failed to upload image. Please try again.',
        key: 'imageUpload'
      })
      e.target.value = ''
    }
  }

  const removeAdditionalImage = (image) => {
    try {
      const imagesArr = product.images.filter(img => img !== image)
      updateProduct('images', imagesArr)
    } catch (error) {
      logger.error('Error removing image:', error)
      message.error('Failed to remove image')
    }
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="file-uploader">
          <div className="uploader__wrapper">
            <input
              type="file"
              className="input-file"
              accept="image/*"
              onChange={(e) => uploadImage(e, true)}
            />
            <div className="uploader__content">
              <p>
                Drag&Drop file here <br /> or <br /> <span>Browse file</span>
              </p>
            </div>
          </div>
          <div className="file-preview">
            <div className="preview">
              {product.mainImage ? (
                <img src={product.mainImage} alt="" />
              ) : (
                <img src={product.mainImage} alt="" />
              )}
            </div>
            <div className="preview-text">
              <p>
                Image must be at least 800 x 800px. <br /> File formats JPG, PNG, GIF,
                MP4, AVI, WMV, MOV
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 mt-4">
        <div className="add-other-images">
          <input
            type="file"
            className="input-file"
            accept="image/*"
            onChange={(e) => uploadImage(e, false)}
          />
          <div className="file-upload-text">
            <i className="fe fe-plus" /> <span>Add additional product images</span>
          </div>
        </div>
      </div>
      <div className="col-12 mt-4">
        <div className="row images-list">
          {product.images.map((image) => (
            <div className="col-12 col-md-6 col-lg-3" key={image}>
              <div className="image-wrapper">
                <span
                  className="remove-image"
                  onClick={() => removeAdditionalImage(image)}
                >
                  <span className="fe fe-trash" />
                </span>
                <img src={image.original} alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProductImages
